import { PanelItemProps } from 'components/layout/Panel/PanelItem';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

type ContainerProps = {
  hasDefaultPadding?: boolean;
  hasBackgroundColor?: boolean;
  size?: 'LG' | 'MD';
  as?: 'div' | 'section';
} & JSX.IntrinsicElements['div'] &
  ParentClassNameProp &
  Pick<PanelItemProps, 'paddingX' | 'paddingY'>;

const useStyles = makeStyles<ContainerProps>()({
  container: (props) => [
    'w-full',
    'mx-auto',
    {
      'max-w-7xl': props?.size === 'MD',
      'px-6': props?.paddingX === 'MD' && props?.hasDefaultPadding,
      'px-8': props?.paddingX === 'LG' && props?.hasDefaultPadding,
      'max-w-screen-2xl': props?.size === 'LG',
      'bg-primaryBackground': props?.hasBackgroundColor
    },
    props?.className
  ]
});

export const Container: React.FC<ContainerProps> = React.memo(
  ({
    children,
    id,
    paddingX = false,
    paddingY = false,
    as = 'div',
    hasDefaultPadding = true,
    hasBackgroundColor = false,
    size = 'MD',
    ...props
  }) => {
    const styles = useStyles({
      paddingX,
      paddingY,
      hasDefaultPadding,
      hasBackgroundColor,
      size,
      ...props
    });
    const Element = as === 'section' ? 'section' : 'div';

    return (
      <Element id={id} className={styles.container}>
        {children}
      </Element>
    );
  }
);

Container.displayName = 'Container';
